import { render, staticRenderFns } from "./PaymentMethodInstallmentsTable.vue?vue&type=template&id=594d0bfa&scoped=true"
import script from "./PaymentMethodInstallmentsTable.vue?vue&type=script&lang=js"
export * from "./PaymentMethodInstallmentsTable.vue?vue&type=script&lang=js"
import style0 from "./PaymentMethodInstallmentsTable.vue?vue&type=style&index=0&id=594d0bfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594d0bfa",
  null
  
)

export default component.exports